import React from 'react';
import PropTypes from 'prop-types';
import IconPreview from './IconPreview';

const ColorPalettePicker = ({ colors, color, setColor, selectedIcon }) => {
  if (!selectedIcon) return null;

  return (
    <div className="scrivito_content_group second-row">
      <h3>{I18n.t('icon_widget.details.color')}</h3>
      {colors.map((col, index) => (
        <span
          key={index}
          title={col}
          className={`color-selector ${col} ${color === col ? 'active' : ''}`}
          onClick={() => setColor(col)}
        >
          <IconPreview icon={selectedIcon} />
        </span>
      ))}
    </div>
  );
};

ColorPalettePicker.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
  selectedIcon: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default ColorPalettePicker;
