import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './styles/IconSelector-styles.scss';
import { PulseLoader } from 'react-spinners';
import IconPreview from './components/IconPreview';
import SearchInput from './components/SearchInput';
import useIconSelector from './hooks/useIconSelector';
import ColorPalettePicker from './components/ColorPalettePicker';
import FontSizeController from './components/FontSizeController';
import SelectCategoryPicker from './components/SelectCategoryPicker';

export const IconSelector = ({
  widget_id,
  defaultColor,
  colors,
  defaultIcon,
  font_size,
  icon_attribute = 'icon',
  color_attribute = 'color',
  font_size_attribute = 'font_size',
}) => {
  const {
    categories,
    icons,
    fontSize,
    color,
    selectedIcon,
    loading,
    setKey,
    setSearchQuery,
    setFontSize,
    setColor,
    setSelectedIcon,
  } = useIconSelector({
    defaultIcon,
    font_size,
    defaultColor,
    icon_attribute,
    color_attribute,
    font_size_attribute,
    widget_id,
  });

  const onTextChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const onRangeChange = (event) => {
    setFontSize(Number(event.target.value));
  };

  return (
    <div className="icon-selector">
      <Row>
        <Col sm={12}>
          <div className="scrivito_content_group">
            <SearchInput onTextChange={onTextChange} />
            <div className="category-container">
              <SelectCategoryPicker categories={categories} setKey={setKey} />
              <div>
                <PulseLoader size={4} loading={loading} />
                <ul className="scrivito_enum_editor icon-selection">
                  {icons.map((icon) => (
                    <li
                      key={icon.id}
                      className={
                        icon.id === selectedIcon?.id
                          ? 'scrivito_enum_active'
                          : ''
                      }
                      onClick={() => setSelectedIcon(icon)}
                    >
                      <IconPreview icon={icon} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <div className="scrivito_content_group second-row">
            <h3>{I18n.t('icon_widget.details.preview')}</h3>
            {selectedIcon ? (
              <div className="preview-box">
                <IconPreview
                  icon={selectedIcon}
                  fontSize={fontSize}
                  color={color}
                />
              </div>
            ) : (
              <p className="small">
                {I18n.t('icon_widget.details.choose_to_view')}
              </p>
            )}
          </div>
        </Col>
        <Col sm={4}>
          <FontSizeController
            fontSize={fontSize}
            onRangeChange={onRangeChange}
            selectedIcon={selectedIcon}
          />
        </Col>
        <Col sm={5}>
          <ColorPalettePicker
            colors={colors}
            color={color}
            setColor={setColor}
            selectedIcon={selectedIcon}
          />
        </Col>
      </Row>
    </div>
  );
};

IconSelector.propTypes = {
  widget_id: PropTypes.string.isRequired,
  defaultColor: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultIcon: PropTypes.string.isRequired,
  font_size: PropTypes.number,
  icon_attribute: PropTypes.string,
  color_attribute: PropTypes.string,
  font_size_attribute: PropTypes.string,
};

IconSelector.defaultProps = {
  font_size: 100,
  icon_attribute: 'icon',
  color_attribute: 'color',
  font_size_attribute: 'font_size',
};

export default IconSelector;
