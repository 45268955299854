export const drawResult = (result, scoreType) => {
  const c = document.getElementById('risk-canvas1');
  drawLine({
    canvas: c,
    lineHeight: 1194,
    result,
    canvasSize: { width: 1758, height: 1502 },
  });

  if (scoreType === 'SMR') {
    const c2 = document.getElementById('risk-canvas2');
    const lh = 960;
    const config = {
      canvas: c2,
      lineHeight: lh,
      result,
      canvasSize: { width: 1756, height: 1102 },
    };

    if (result.result < 49) {
      config.lineHeight = lh / 2;
      config.yOffset = lh / 2;
    }
    drawLine(config);
  }
};

export const drawLine = (config) => {
  const { canvas, lineHeight, yOffset = 0, result, canvasSize } = config;
  const { width: customWidth, height: customHeight } = canvasSize;

  const width = customWidth;
  const height = customHeight;
  canvas.width = width;
  canvas.height = height;

  const maxX = 1296;
  const imageScale = width / 1758;
  const xoffset = 360 * imageScale;

  const valPercent = parseFloat(result.result) / 100;
  const xpos = valPercent * (imageScale * maxX) + xoffset;

  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.beginPath();

  const scaledYOffset = yOffset * imageScale;
  ctx.moveTo(xpos, scaledYOffset);

  const lineYEnd = Math.round(lineHeight * imageScale) + scaledYOffset;
  ctx.lineTo(xpos, lineYEnd);

  ctx.lineWidth = 5;
  ctx.strokeStyle = 'red';
  ctx.stroke();

  ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
  ctx.font = '40px sans-serif';
  ctx.fillRect(xpos - 240, lineYEnd - 100, 240, 67);
  ctx.fillStyle = 'white';
  ctx.fillText('Your Patient', xpos - 230, lineYEnd - 50);
};
