import React from 'react';
import PropTypes from 'prop-types';
import { getIconClass } from '../utils/iconClassBuilder';

const IconPreview = ({ icon, color = '', fontSize }) => {
  if (!icon) return null;

  if (!fontSize) {
    fontSize = '2rem';
  } else if (!Number.isNaN(Number(fontSize))) {
    fontSize = fontSize + '%';
  }

  let classes = getIconClass(icon);
  if (color !== '') classes += ` ${color}`;
  return (
    <i className={classes} title={icon.label} style={{ fontSize: fontSize }} />
  );
};

IconPreview.propTypes = {
  icon: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconPreview;
