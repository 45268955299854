import React, { useEffect, useState } from 'react';
import ThreeDimensionalObjectViewer from './three_dimensional_object_widget/ThreeDimensionalObjectViewer';
import RangeSlider from './globals/details_controls/RangeSlider';
import OptionSelector from './globals/details_controls/OptionSelector';
import ColorOption from './globals/details_controls/options/ColorOption';
import TextOption from './globals/details_controls/options/TextOption';
import PropTypes from 'prop-types';

export default function ThreeDimensionalObjectWidgetDetails({
  widget_id,
  link_to_object = '',
  link_to_material = '',
  link_to_texture = '',
  rotation_speed,
  rotation_active,
  ambient_light,
  copyright,
  zoom,
  background_color,
  colors,
  aspect_ratio,
  aspect_ratios,
  tooltip_texts = {},
}) {
  const writeInScrivito = (attribute, value) => {
    const element = document.querySelector(
      'div[data-scrivito-field-name="' +
        attribute +
        '"][data-scrivito-private-field-widget-id="' +
        widget_id +
        '"]'
    );
    $(element).scrivito('save', value);
  };

  // Rotation speed
  const [rotationSpeed, setRotationSpeed] = useState(rotation_speed);
  const speedConversionTable = {
    1: 0.0,
    2: 0.001,
    3: 0.002,
    4: 0.003,
    5: 0.004,
    6: 0.006,
    7: 0.008,
    8: 0.01,
    9: 0.014,
    10: 0.02,
  };
  useEffect(() => {
    writeInScrivito('rotation_speed', rotationSpeed);
  }, [rotationSpeed]);

  // Rotation active or inactive by default
  const [rotationActive, setRotationActive] = useState(rotation_active);
  useEffect(() => {
    writeInScrivito('rotation_active', rotationActive);
  }, [rotationActive]);

  // Ambient light
  const [ambientLight, setAmbientLight] = useState(ambient_light);
  const ambientLightConversionTable = {
    1: 0.0,
    2: 0.2,
    3: 0.4,
    4: 0.6,
    5: 0.8,
    6: 1.0,
    7: 1.2,
    8: 1.5,
    9: 2.0,
    10: 3.0,
  };
  useEffect(() => {
    writeInScrivito('ambient_light', ambientLight);
  }, [ambientLight]);

  // Zoom
  const [zoomLevel, setZoomLevel] = useState(zoom);
  const zoomConversionTable = {
    1: 0.2,
    2: 0.4,
    3: 0.6,
    4: 0.8,
    5: 1.0,
    6: 1.2,
    7: 1.5,
    8: 1.8,
    9: 2.2,
    10: 3.0,
  };
  useEffect(() => {
    writeInScrivito('zoom', zoomLevel);
  }, [zoomLevel]);

  // Background color
  const [backgroundColor, setBackgroundColor] = useState(background_color);
  useEffect(() => {
    writeInScrivito('background_color', backgroundColor);
  }, [backgroundColor]);

  // Aspect ratio
  const [aspectRatio, setAspectRatio] = useState(aspect_ratio);
  useEffect(() => {
    writeInScrivito('aspect_ratio', aspectRatio);
  }, [aspectRatio]);

  return (
    <div className="ThreeDFiles-edit-controls">
      <div className="panel panel-preview">
        <div className="scrivito_content_group">
          <OptionSelector
            label={I18n.t(
              'three_dimensional_object_widget.details.aspect_ratio'
            )}
            currentState={aspectRatio}
            setterFunction={setAspectRatio}
            options={aspect_ratios}
            OptionComponent={TextOption}
          />
        </div>
        <div className="canvas">
          <ThreeDimensionalObjectViewer
            link_to_object={link_to_object}
            link_to_material={link_to_material}
            link_to_texture={link_to_texture}
            rotation_speed={rotationSpeed}
            rotation_active={rotationActive}
            ambient_light={ambientLight}
            copyright={copyright}
            zoom={zoomLevel}
            background_color={backgroundColor}
            aspect_ratio={aspectRatio}
          />
        </div>
      </div>
      <div className="panel panel-config">
        <RangeSlider
          label={I18n.t('three_dimensional_object_widget.details.zoom_level')}
          currentState={zoomLevel}
          setterFunction={setZoomLevel}
          conversionTable={zoomConversionTable}
        />
        <RangeSlider
          label={I18n.t(
            'three_dimensional_object_widget.details.ambient_light'
          )}
          currentState={ambientLight}
          setterFunction={setAmbientLight}
          conversionTable={ambientLightConversionTable}
          tooltip={tooltip_texts['ambient_light_desc']}
        />
        <RangeSlider
          label={I18n.t(
            'three_dimensional_object_widget.details.rotation_speed'
          )}
          currentState={rotationSpeed}
          setterFunction={setRotationSpeed}
          conversionTable={speedConversionTable}
          tooltip={tooltip_texts['rotation_desc']}
        />
        <OptionSelector
          label={I18n.t(
            'three_dimensional_object_widget.details.rotation_active'
          )}
          currentState={rotationActive}
          setterFunction={setRotationActive}
          options={['yes', 'no']}
          OptionComponent={TextOption}
          translationPath={'enum_lists.yes_no'}
          tooltip={tooltip_texts['rotation_active_desc']}
        />

        <OptionSelector
          label={I18n.t(
            'three_dimensional_object_widget.details.background_color'
          )}
          currentState={backgroundColor}
          setterFunction={setBackgroundColor}
          options={colors}
          OptionComponent={ColorOption}
        />
      </div>
    </div>
  );
}

ThreeDimensionalObjectWidgetDetails.propTypes = {
  widget_id: PropTypes.string.isRequired,
  link_to_object: PropTypes.string,
  link_to_material: PropTypes.string,
  link_to_texture: PropTypes.string,
  rotation_speed: PropTypes.number.isRequired,
  rotation_active: PropTypes.string.isRequired,
  ambient_light: PropTypes.number.isRequired,
  copyright: PropTypes.string.isRequired,
  zoom: PropTypes.number.isRequired,
  background_color: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  aspect_ratio: PropTypes.string.isRequired,
  aspect_ratios: PropTypes.arrayOf(PropTypes.string).isRequired,
  tooltip_texts: PropTypes.objectOf(PropTypes.string),
};
