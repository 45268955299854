export const getIconClass = (icon) => {
  if (!icon) return 'fas fa-question';
  const iconStyleCss = renderIconStyleClass(icon.styles);
  return `${iconStyleCss} fa-${icon.id}`;
};

export const renderIconStyleClass = (iconStyle) => {
  if (iconStyle.includes('solid')) {
    return 'fas';
  } else if (iconStyle.includes('regular')) {
    return 'far';
  } else if (iconStyle.includes('brands')) {
    return 'fab';
  }
  return '';
};

export const renderIconStyle = (iconStyleClass) => {
  if (iconStyleClass === 'fas') {
    return 'solid';
  } else if (iconStyleClass === 'far') {
    return 'regular';
  } else if (iconStyleClass === 'fab') {
    return 'brands';
  }
  return '';
};
