export default [
  { id: 'Age', label: 'Age [years]', min: 50, max: 115, step: 1, value: 60 },
  { id: 'BMI', label: 'BMI [kg/m²]', min: 15, max: 40, step: 0.1, value: 30 },
  {
    id: 'CAD',
    label: 'History of coronary artery disease',
    type: 'radio',
    min: 0,
    max: 1,
    step: 1,
    value: 1,
  },
  {
    id: 'NYHA',
    label: 'NYHA functional class',
    min: 1,
    max: 4,
    choices: [
      {
        label: 'NYHA I',
        value: 1,
      },
      {
        label: 'NYHA II',
        value: 2,
      },
      {
        label: 'NYHA III',
        value: 3,
      },
      {
        label: 'NYHA IV',
        value: 4,
      },
    ],
    value: 1,
  },
  {
    id: 'Pleural_Effusion',
    label: 'Pleural effusion',
    type: 'radio',
    min: 0,
    max: 1,
    step: 1,
    value: 0,
  },
  {
    id: 'Any_sign_of_right_heart_failure',
    label: 'Signs of right heart failure',
    type: 'radio',
    min: 0,
    max: 1,
    step: 1,
    value: 1,
  },
  {
    id: 'eGFR',
    label: 'eGFR [mL/min/1.73m²]',
    min: 0,
    max: 120,
    step: 1,
    value: 15,
  },
  {
    id: 'NT_proBNP',
    label: 'NT-proBNP [pg/mL]',
    min: 0,
    max: 40000,
    step: 1,
    value: 2000,
  },
  {
    id: 'Hb',
    label: 'Hemoglobin [g/dL]',
    min: 5,
    max: 20,
    step: 0.1,
    value: 12,
  },
  {
    id: 'GOT',
    label: 'Aspartate aminotransferase [U/L]',
    min: 10,
    max: 200,
    step: 1,
    value: 80,
  },
  { id: 'LVEF_pre', label: 'LVEF [%]', min: 10, max: 70, step: 0.1, value: 40 },
  {
    id: 'MR_pre',
    label: 'Mitral regurgitation severity',
    min: 1,
    max: 4,
    step: 1,
    choices: [
      { label: 'I/IV°', value: 1 },
      { label: 'II/IV°', value: 2 },
      { label: 'III/IV°', value: 3 },
      { label: 'IV/IV°', value: 4 },
    ],
    value: 1,
  },
  {
    id: 'mPAP_RHC_pre',
    label: 'Mean pulmonary artery pressure [mmHg]',
    min: 10,
    max: 50,
    step: 1,
    value: 30,
  },
  { id: 'TAPSE_pre', label: 'TAPSE [mm]', min: 5, max: 30, step: 1, value: 12 },
  {
    id: 'RV_mid_diameter_pre',
    label: 'Right ventricular diameter (mid) [mm]',
    min: 10,
    max: 60,
    step: 0.1,
    value: 50,
  },
  {
    id: 'TV_annular_diameter',
    label: 'Tricuspid valve annular diameter [mm]',
    min: 20,
    max: 60,
    step: 1,
    value: 40,
  },
  {
    id: 'Vena_contracta_pre',
    label: 'TR vena contracta width [mm]',
    min: 0,
    max: 20,
    step: 0.1,
    value: 8,
  },
  {
    id: 'RA_area',
    label: 'RA area [cm²]',
    min: 10,
    max: 50,
    step: 1,
    value: 40,
  },
];
