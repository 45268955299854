import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';

const FormSubmitButton = ({ handleSubmit, values, result, scoreType }) => {
  return (
    <div className="widget-wrapper margin-small">
      <Button
        variant="primary"
        size="lg"
        className="mb-3"
        onClick={handleSubmit}
        disabled={
          Object.keys(values).length === 0 || result?.status === 'pending'
        }
      >
        <FontAwesomeIcon icon={faCalculator} />
        &nbsp;{' '}
        {scoreType === 'TR'
          ? I18n.t('euro_smr_score_widget.show.determine_score_tr')
          : I18n.t('euro_smr_score_widget.show.determine_score_smr')}
      </Button>
    </div>
  );
};

export default FormSubmitButton;
