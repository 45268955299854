import React from 'react';
import PropTypes from 'prop-types';

export default function PlayPauseButton({ setterFunction, currentState }) {
  const handlePlayPause = () => {
    setterFunction(!currentState);
  };

  return (
    <button type="button" aria-label="Play" onClick={handlePlayPause}>
      <i
        className={currentState ? 'icon fas fa-pause' : 'icon fas fa-play'}
      ></i>
    </button>
  );
}

PlayPauseButton.propTypes = {
  setterFunction: PropTypes.func.isRequired,
  currentState: PropTypes.bool.isRequired,
};
