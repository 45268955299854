import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

const SearchInput = ({ onTextChange }) => {
  return (
    <div>
      <Form.Control
        type="text"
        placeholder={I18n.t('icon_widget.details.search')}
        onChange={onTextChange}
      />
    </div>
  );
};

SearchInput.propTypes = {
  onTextChange: PropTypes.func.isRequired,
};

export default SearchInput;
