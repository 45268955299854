import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import ThreeDimensionalObject from './ThreeDimensionalObject';
import { OrbitControls } from '@react-three/drei';
import Loading from './Loading';
import FullScreenButton from '../globals/viewer_controls/FullScreenButton';
import PlayPauseButton from '../globals/viewer_controls/PlayPauseButton';
import PropTypes from 'prop-types';
import HelpHoverButton from '../globals/viewer_controls/HelpHoverButton';
import ZoomButtons from '../globals/viewer_controls/ZoomButtons';

export default function ThreeDimensionalObjectViewer({
  link_to_object = '',
  link_to_material = '',
  link_to_texture = '',
  ambient_light,
  copyright,
  rotation_speed,
  rotation_active,
  zoom,
  background_color,
  aspect_ratio,
  alt_text = '',
}) {
  // Camera
  // https://threejs.org/manual/#en/cameras
  const fov = 50;
  const near = 0.1;
  const far = 10000;
  const position = [0, 0, 1];

  // Ambient light
  const ambColor = 0xffffff;
  const ambIntensity = ambient_light;
  // Directional light
  const dirColor = 0xffffff;
  const dirIntensity = 1.5;
  const dirPosition = [-1, 2, 4];

  // Rotation
  let rotationActiveBool = rotation_active === 'yes';
  // Accessibility: make sure rotation is deactivated by default if the user prefers reduced motion
  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
  if (mediaQuery && mediaQuery.matches) {
    rotationActiveBool = false;
  }
  const [rotationActive, setRotationActive] = useState(rotationActiveBool);

  // User-controlled zoom
  const [zoomLevel, setZoomLevel] = useState(zoom);
  const zoomLevels = [0.2, 0.4, 0.6, 0.8, 1.0, 1.2, 1.5, 1.8, 2.2, 3.0];
  useEffect(() => {
    setZoomLevel(zoom);
  }, [zoom]);

  // Aspect Ratio
  const canvasRef = useRef(null);
  const [canvasHeight, setCanvasHeight] = useState(0);
  useEffect(() => {
    // Set initial size
    updateCanvasSize();
    // Update canvas size on window resize
    window.addEventListener('resize', updateCanvasSize);
    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', updateCanvasSize);
  }, [aspect_ratio]);
  // Function to apply the selected aspect ratio to the width to calculate the height
  const updateCanvasSize = () => {
    if (canvasRef.current) {
      // Get the width of the canvas's parent element (Canvas takes the full width of the parent)
      const width = canvasRef.current.parentNode.clientWidth;
      if (width > 0) {
        let height;
        switch (aspect_ratio) {
          case '2:3':
            height = (width / 2) * 3;
            break;
          case '1:1':
            height = width;
            break;
          case '3:2':
            height = (width / 3) * 2;
            break;
          case '3:1':
            height = width / 3;
            break;
          case '4:1':
            height = width / 4;
            break;
          default:
            height = width;
        }
        setCanvasHeight(height);
      } else {
        // Start polling if width is 0.
        // Needed because in the widget details the component is inside an iframe,
        // and it is mounted before the iframe is fully loaded
        pollForDimensions();
      }
    }
  };
  // Polling function to repeatedly check if the iframe is ready
  const pollForDimensions = () => {
    let retries = 0;
    const maxRetries = 10;
    const interval = setInterval(() => {
      if (canvasRef.current && canvasRef.current.parentNode.clientWidth > 0) {
        updateCanvasSize(); // Recalculate size once width becomes available
        clearInterval(interval); // Stop polling when dimensions are available
      } else if (retries >= maxRetries) {
        clearInterval(interval); // Stop polling after max retries
      }
      retries++;
    }, 100); // Poll every 100ms
  };

  return link_to_object && link_to_material ? (
    <Suspense fallback={<Loading cssHeight={canvasHeight} />}>
      <div className="three-dimensional-object-widget" aria-label={alt_text}>
        <Canvas
          ref={canvasRef}
          fallback={
            <div>
              {I18n.t(
                'three_dimensional_object_widget.show.webgl_not_supported'
              )}
            </div>
          }
          camera={{ fov, near, far, position, zoom }}
          style={{ width: '100%', height: canvasHeight }}
          className={background_color}
        >
          <CanvasModifier zoom={zoomLevel} />
          <ambientLight color={ambColor} intensity={ambIntensity} />
          <directionalLight
            color={dirColor}
            position={dirPosition}
            intensity={dirIntensity}
          />
          <OrbitControls />
          <ThreeDimensionalObject
            linkToObject={link_to_object}
            linkToMaterial={link_to_material}
            linkToTexture={link_to_texture}
            rotationSpeed={rotationActive ? rotation_speed : 0}
          />
        </Canvas>
        <div
          className="control-copyright"
          dangerouslySetInnerHTML={{ __html: copyright }}
        ></div>
        <div className="controls">
          <div className="controls-block">
            <PlayPauseButton
              setterFunction={setRotationActive}
              currentState={rotationActive}
            />
          </div>
          <div className="controls-block">
            <ZoomButtons
              zoom={zoomLevel}
              setZoom={setZoomLevel}
              zoomLevels={zoomLevels}
            />
            <FullScreenButton elementRef={canvasRef} />
            <HelpHoverButton
              text={I18n.t('three_dimensional_object_widget.show.help')}
            />
          </div>
        </div>
      </div>
    </Suspense>
  ) : null;
}

function CanvasModifier({ zoom }) {
  // Contains the default renderer, the scene, the camera, ...
  const three = useThree();

  useEffect(() => {
    three.camera.zoom = zoom;
    three.camera.updateProjectionMatrix();
  }, [zoom, three.camera]);

  return null;
}

ThreeDimensionalObjectViewer.propTypes = {
  link_to_object: PropTypes.string,
  link_to_material: PropTypes.string,
  link_to_texture: PropTypes.string,
  ambient_light: PropTypes.number.isRequired,
  copyright: PropTypes.string.isRequired,
  rotation_speed: PropTypes.number.isRequired,
  rotation_active: PropTypes.string.isRequired,
  zoom: PropTypes.number.isRequired,
  background_color: PropTypes.string.isRequired,
  aspect_ratio: PropTypes.string.isRequired,
  alt_text: PropTypes.string,
};
