import React from 'react';
import PropTypes from 'prop-types';

export default function FullScreenButton({ elementRef }) {
  const handleFullScreen = () => {
    if (elementRef.current) {
      // Check if the element is already in fullscreen
      if (!document.fullscreenElement) {
        elementRef.current.requestFullscreen().catch((err) => {
          console.error(`Error entering fullscreen: ${err}`);
        });
      } else {
        document.exitFullscreen().catch((err) => {
          console.error(`Error exiting fullscreen: ${err}`);
        });
      }
    }
  };

  return (
    <button type="button" aria-label="Fullscreen" onClick={handleFullScreen}>
      <i className={'icon fas fa-expand thin'}></i>
    </button>
  );
}

FullScreenButton.propTypes = {
  elementRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
};
