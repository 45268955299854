import React from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import RadioGroup from './common/RadioGroup';

const FormFieldsRenderer = ({ field, values, updateValue }) => {
  if (field.hasOwnProperty('choices')) {
    return (
      <Select
        id={field.id}
        options={field.choices}
        name={field.id}
        value={field.choices.find(
          (option) => option.value === values[field.id]
        )}
        placeholder="Select an option"
        onChange={(e) => updateValue(field.id, e.value)}
      />
    );
  } else if (field.hasOwnProperty('type') && field.type === 'radio') {
    const radioOptions = [
      { value: '1', label: 'Yes' },
      { value: '0', label: 'No' },
      { value: 'n/a', label: 'n/a' },
    ];

    return (
      <RadioGroup
        id={field.id}
        options={radioOptions}
        value={values[field.id]}
        onChange={(selectedValue) => updateValue(field.id, selectedValue)}
      />
    );
  } else if (field.hasOwnProperty('min')) {
    return (
      <Form.Range
        id={field.id}
        min={field.min}
        max={field.max}
        step={field.step}
        value={values[field.id] || 0}
        onChange={(e) => updateValue(field.id, e.target.value)}
      />
    );
  }

  return null;
};
export default FormFieldsRenderer;
