import React, { useRef } from 'react';
import { useFrame, useLoader } from '@react-three/fiber';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import PropTypes from 'prop-types';

export default function ThreeDimensionalObject({
  linkToObject,
  linkToMaterial,
  linkToTexture,
  rotationSpeed,
}) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();
  // Subscribe this component to the render-loop, rotate the mesh every frame
  const rotate = () => {
    ref.current.rotation.y += rotationSpeed;
  };
  useFrame((state, delta) => rotate());

  // Load material and object.
  // The material could also reference an additional texture file (jpg, png),
  // usually in the same path.

  // For testing place the files in the /public folder and address them with '/filename.obj'
  // const mtl = useLoader(MTLLoader, '/textured.mtl');
  // const obj = useLoader(OBJLoader, '/textured.obj', (loader) => {
  //   mtl.preload();
  //   loader.setMaterials(mtl);
  // });

  // Load MTL and OBJ dynamically using the provided links
  let obj = null;
  if (linkToObject && linkToMaterial) {
    const mtl = useLoader(MTLLoader, linkToMaterial);

    // We currently support only one single material with one texture
    const materialName = Object.keys(mtl.materialsInfo)[0];
    if (linkToTexture) {
      mtl.materialsInfo[materialName].map_kd = linkToTexture;
      // Reload material after having changed the path to the texture file
      mtl.preload();
    }

    obj = useLoader(OBJLoader, linkToObject, (loader) => {
      loader.setMaterials(mtl);
    });
  }

  return obj ? (
    // For testing
    // <mesh ref={ref} position={[0, 3, -2]}>
    //   <boxGeometry args={[1, 1, 1]} />
    //   <meshStandardMaterial color='lightgreen' />
    // </mesh>
    <primitive ref={ref} object={obj} />
  ) : null;
}

ThreeDimensionalObject.propTypes = {
  linkToObject: PropTypes.string.isRequired,
  linkToMaterial: PropTypes.string.isRequired,
  linkToTexture: PropTypes.string.isRequired,
  rotationSpeed: PropTypes.number.isRequired,
};
