import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';

export default function RangeSlider({
  label,
  currentState,
  setterFunction,
  conversionTable,
  min = 1,
  max = 10,
  step = 1,
  tooltip = '',
}) {
  const convertCurrentState = () => {
    const key = Object.keys(conversionTable).find(
      (key) => conversionTable[key] === currentState
    );
    return key ? parseInt(key) : min;
  };
  const changeValue = (e) => {
    setterFunction(conversionTable[parseInt(e.target.value)]);
  };

  return (
    <div className="react-globalwidget-range scrivito_content_group">
      <label>
        <span>{label}</span>
        <span className="flex">
          <input
            type={'number'}
            onChange={changeValue}
            min={min}
            max={max}
            step={step}
            value={convertCurrentState()}
          />
          <input
            type={'range'}
            onChange={changeValue}
            min={min}
            max={max}
            step={step}
            value={convertCurrentState()}
          />
        </span>
      </label>
      {tooltip ? <Tooltip text={tooltip} /> : null}
    </div>
  );
}

RangeSlider.propTypes = {
  label: PropTypes.string.isRequired,
  currentState: PropTypes.number.isRequired,
  setterFunction: PropTypes.func.isRequired,
  conversionTable: PropTypes.objectOf(PropTypes.number).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  tooltip: PropTypes.string,
};
