import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import FormFieldsRenderer from './FormFieldsRenderer';

const FormRowRenderer = ({ field, index, values, updateValue }) => {
  return (
    <Row key={index} className="field-row">
      <Col sm={4} className="label-col">
        <label htmlFor={field.id}>
          {I18n.t(`euro_smr_score_widget.parameters.${field.id}`)}
        </label>
      </Col>
      <Col sm={6}>
        <FormFieldsRenderer
          field={field}
          values={values}
          updateValue={updateValue}
        />
      </Col>
      <Col sm={2}>
        <Form.Control
          value={values[field.id] || 'n/a'}
          id={field.id}
          min={field.min}
          max={field.max}
          type={values[field.id] ? 'number' : 'text'}
          onChange={(e) => updateValue(field.id, e.target.value)}
          onBlur={(e) => {
            if (e.target.value === '') updateValue(field.id, 'n/a');
            if (e.target.value < field.min) updateValue(field.id, field.min);
            if (e.target.value > field.max) updateValue(field.id, field.max);
          }}
        />
      </Col>
    </Row>
  );
};
export default FormRowRenderer;
