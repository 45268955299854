import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

const SelectCategoryPicker = ({ categories, setKey }) => {
  return (
    <div className="category-filter">
      <Form.Select
        size="lg"
        onChange={(e) => setKey(e.target.value)}
        className="react-select-container"
      >
        {categories.map((category) => (
          <option key={category} value={category}>
            {I18n.t(`icon_widget.details.${category}`)}
          </option>
        ))}
      </Form.Select>
    </div>
  );
};

SelectCategoryPicker.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  setKey: PropTypes.func.isRequired,
};

export default SelectCategoryPicker;
