import React from 'react';
import PropTypes from 'prop-types';

export default function TextOption({ option, translationPath = '' }) {
  const generateText = () => {
    return translationPath ? I18n.t(translationPath + '.' + option) : option;
  };

  return (
    <button type="button" aria-label={option}>
      {generateText()}
    </button>
  );
}

TextOption.propTypes = {
  option: PropTypes.string.isRequired,
  translationPath: PropTypes.string,
};
