import React from 'react';
import PropTypes from 'prop-types';

export default function CopyButton({ text = '' }) {
  return (
    <button
      type="button"
      aria-label="Copy"
      onClick={() => navigator.clipboard.writeText(text)}
    >
      <i className={'icon fas fa-copy'}></i>
    </button>
  );
}

CopyButton.propTypes = {
  text: PropTypes.string,
};
