import React from 'react';
import PropTypes from 'prop-types';

export default function ColorOption({ option }) {
  return (
    <button className="color" type="button" aria-label={option}>
      <span className={option + ' color-option'}></span>
    </button>
  );
}

ColorOption.propTypes = {
  option: PropTypes.string.isRequired,
};
