import React from 'react';
import PropTypes from 'prop-types';

export default function Loading({ cssHeight }) {
  return (
    <div style={{ height: cssHeight }}>
      <span className={'spinner'}></span>
    </div>
  );
}

Loading.propTypes = {
  cssHeight: PropTypes.number.isRequired,
};
