import React from 'react';
import { Card, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const ResultStatusCard = ({ result, scoreType }) => {
  return (
    <div className="widget-wrapper margin-small">
      <Collapse in={true}>
        <Card className="mt-3">
          <Card.Body>
            <Card.Title>
              {scoreType === 'SMR'
                ? I18n.t(`euro_smr_score_widget.show.completed_smr`)
                : I18n.t(`euro_smr_score_widget.show.completed_tr`)}
            </Card.Title>
            <Card.Text>
              {result.status === 'pending' && <span className="spinner"></span>}
              {result.status === 'error' && (
                <>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  &nbsp;{result.result}
                </>
              )}
              {result.status === 'completed' && (
                <span className="result">
                  {parseFloat(result.result).toFixed(2)}
                </span>
              )}
            </Card.Text>
          </Card.Body>
        </Card>
      </Collapse>
    </div>
  );
};

export default ResultStatusCard;
