import React from 'react';
import { Table } from 'react-bootstrap';

const FormSelectedValues = ({ values, scoreType }) => {
  return (
    <div className="widget-wrapper margin-small">
      {Object.entries(values).length > 0 ? (
        <>
          <p className="margin-small">
            {I18n.t('euro_smr_score_widget.show.selected_parameters')}
          </p>
          <Table striped hover={false}>
            <tbody>
              {Object.entries(values).map(([key, value]) => {
                if (value !== 'n/a') {
                  return (
                    <tr key={key}>
                      <td>{key}</td>
                      <td>{value}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <p>
          {scoreType === 'TR'
            ? I18n.t('euro_smr_score_widget.show.select_tr_values_to_begin')
            : I18n.t('euro_smr_score_widget.show.select_smr_values_to_begin')}
        </p>
      )}
    </div>
  );
};

export default FormSelectedValues;
