import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderIconStyle, getIconClass } from '../utils/iconClassBuilder';

const useIconSelector = ({
  defaultIcon,
  font_size,
  defaultColor,
  icon_attribute,
  color_attribute,
  font_size_attribute,
  widget_id,
}) => {
  const initializeSelectedIcon = () =>
    defaultIcon
      ? {
          id: defaultIcon.split(' fa-')[1],
          styles: [renderIconStyle(defaultIcon.split(' fa-')[0])],
        }
      : null;

  const [categories, setCategories] = useState([]);
  const [icons, setIcons] = useState([]);
  const [fontSize, setFontSize] = useState(font_size);
  const [color, setColor] = useState(defaultColor);
  const [selectedIcon, setSelectedIcon] = useState(initializeSelectedIcon());
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [key, setKey] = useState(null);

  const updateElement = (field, value, widget_id) => {
    const element = document.querySelector(
      `div[data-scrivito-field-name="${field}"][data-scrivito-private-field-widget-id="${widget_id}"]`
    );
    if (element) {
      element.innerText = value;
      $(element).scrivito('save', value);
    }
  };

  const fetchData = async (endpoint, setState) => {
    setLoading(true);
    try {
      const response = await fetch(endpoint);
      const data = await response.json();
      setState(data);
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    } finally {
      setLoading(false);
    }
  };

  const performSearch = async () => {
    if (!searchQuery) return;
    await fetchData(
      `/api/v1/icon_widget/search.json?q=${searchQuery}`,
      setIcons
    );
  };

  useEffect(() => {
    const fetchCategoriesData = async () => {
      await fetchData('/api/v1/icon_widget/categories.json', (data) => {
        setCategories(data);
        if (data.length > 0) {
          setKey(data[0]);
        }
      });
    };

    fetchCategoriesData().catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const fetchIconsData = async () => {
      if (key) {
        await fetchData(
          `/api/v1/icon_widget/icons_by_category.json?category=${key}`,
          setIcons
        );
      }
    };

    fetchIconsData().catch((error) => console.log(error));
  }, [key]);

  useEffect(() => {
    if (searchQuery) {
      const timeoutId = setTimeout(() => performSearch(), 500);
      return () => clearTimeout(timeoutId);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (selectedIcon) {
      updateElement(icon_attribute, getIconClass(selectedIcon), widget_id);
      updateElement(color_attribute, color, widget_id);
      updateElement(font_size_attribute, fontSize, widget_id);
    }
  }, [
    selectedIcon,
    color,
    fontSize,
    icon_attribute,
    color_attribute,
    font_size_attribute,
    widget_id,
  ]);

  return {
    categories,
    icons,
    fontSize,
    color,
    selectedIcon,
    loading,
    setKey,
    setSearchQuery,
    setFontSize,
    setColor,
    setSelectedIcon,
    searchQuery,
  };
};

useIconSelector.propTypes = {
  defaultIcon: PropTypes.string,
  font_size: PropTypes.number.isRequired,
  defaultColor: PropTypes.string.isRequired,
  icon_attribute: PropTypes.string.isRequired,
  color_attribute: PropTypes.string.isRequired,
  font_size_attribute: PropTypes.string.isRequired,
  widget_id: PropTypes.string.isRequired,
};

export default useIconSelector;
