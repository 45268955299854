import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';

export default function HelpHoverButton({ text }) {
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  const popoverID = useRef(`threeD-${Math.random().toString(36).substr(2, 9)}`);

  const handleClick = (e) => {
    setActive(!active);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <div className="helpbutton-partial">
      <span
        id={popoverID.current}
        role="tooltip"
        className={active || hover ? 'active' : 'inactive'}
      >
        {text}
      </span>
      <button
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        type="button"
        aria-label="Help"
        aria-describedby={popoverID.current}
      >
        <i className={'icon fas fa-question-circle'}></i>
      </button>
    </div>
  );
}

HelpHoverButton.propTypes = {
  text: PropTypes.string.isRequired,
};
