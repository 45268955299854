import React from 'react';
import PropTypes from 'prop-types';

export default function ZoomButtons({ zoom, setZoom, zoomLevels }) {
  const handleZoomOut = () => {
    const currentIndex = zoomLevels.indexOf(zoom);
    if (currentIndex > 0) {
      setZoom(zoomLevels[currentIndex - 1]);
    }
  };
  const handleZoomIn = () => {
    const currentIndex = zoomLevels.indexOf(zoom);
    if (currentIndex < zoomLevels.length - 1) {
      setZoom(zoomLevels[currentIndex + 1]);
    }
  };

  return (
    <>
      <button
        type="button"
        aria-label="Zoom out"
        onClick={handleZoomOut}
        disabled={zoom === zoomLevels[0]}
      >
        <i className={'icon fas fa-search-minus thin'}></i>
      </button>
      <button
        type="button"
        aria-label="Zoom in"
        onClick={handleZoomIn}
        disabled={zoom === zoomLevels[zoomLevels.length - 1]}
      >
        <i className={'icon fas fa-search-plus thin'}></i>
      </button>
    </>
  );
}

ZoomButtons.propTypes = {
  zoom: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
  zoomLevels: PropTypes.arrayOf(PropTypes.number).isRequired,
};
