import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './Tooltip';

export default function OptionSelector({
  label,
  currentState,
  setterFunction,
  options,
  OptionComponent,
  translationPath = '',
  tooltip = '',
}) {
  return (
    <div className="react-option-selector scrivito_content_group">
      <label>
        {label}
        <div className="content-wrapper">
          {options.map((option, index) => {
            return (
              <div
                key={index}
                onClick={() => setterFunction(option)}
                className={`content-item ${currentState === option ? 'is-active' : ''}`}
              >
                <OptionComponent
                  option={option}
                  translationPath={translationPath}
                />
              </div>
            );
          })}
        </div>
      </label>
      {tooltip ? <Tooltip text={tooltip} /> : null}
    </div>
  );
}

OptionSelector.propTypes = {
  label: PropTypes.string.isRequired,
  currentState: PropTypes.string.isRequired,
  setterFunction: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  OptionComponent: PropTypes.elementType.isRequired,
  translationPath: PropTypes.string,
  tooltip: PropTypes.string,
};
