import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import IconPreview from './IconPreview';

const FontSizeController = ({ fontSize, onRangeChange, selectedIcon }) => {
  return (
    <div className="scrivito_content_group second-row">
      <h3>{I18n.t('icon_widget.details.font_size')}</h3>
      <div className="font-size-selector">
        <Form.Control
          type="number"
          value={fontSize}
          min={50}
          max={1500}
          onChange={onRangeChange}
        />
        <Form.Range
          min={50}
          max={1000}
          onChange={onRangeChange}
          value={fontSize}
        />
      </div>
      <p className="small">
        {I18n.t('icon_widget.details.font_size_desc')}
        &nbsp;
        <IconPreview icon={selectedIcon} fontSize="1rem" />
      </p>
    </div>
  );
};

FontSizeController.propTypes = {
  fontSize: PropTypes.number.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  selectedIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FontSizeController;
