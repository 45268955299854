import React from 'react';

const ResultScoreDiagrams = ({
  scoreType,
  percentileUrlTR,
  percentileUrl,
  outcomesUrl,
}) => {
  return (
    <>
      {scoreType === 'SMR' ? (
        <>
          <canvas
            id="risk-canvas1"
            className="percentile-rank"
            style={{ backgroundImage: `url(${percentileUrl})` }}
          />
          <canvas
            id="risk-canvas2"
            className="percentile-rank"
            style={{ backgroundImage: `url(${outcomesUrl})` }}
          />
        </>
      ) : (
        <canvas
          id="risk-canvas1"
          className="percentile-rank"
          style={{ backgroundImage: `url(${percentileUrlTR})` }}
        />
      )}
    </>
  );
};

export default ResultScoreDiagrams;
